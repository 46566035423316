import React from 'react'
import FormWithAccount from '../../components/ProtectionPlansForm/FormWithAccount'
import {
  Header,
  FormCard,
  PageSelection,
  Content,
} from '../../components/ProtectionPlansForm/FormStyle'
import ProtectionPlanDetails from '../../components/ProtectionPlansForm/ProtectionPlanDetails'
import imageWearable from '../../images/protection-form-image1.png'
import { PageTitle } from '../../components/ProtectionPlansForm/Content'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout/Layout'

const ProtectionForm = (): JSX.Element => {
  return (
    <Layout>
      <SEO
        title="Lively Protection Plan"
        description="Get peace of mind with the Lively Protection Plan"
        noIndex={true}
      />
      <PageSelection>
        <Header>
          <div className="container">
            <div className="page-header">
              <div className="header-left">
                <PageTitle />
              </div>
              <div className="header-right">
                <img
                  className="main-img"
                  src={imageWearable}
                  alt="Protection Form"
                />
              </div>
            </div>
          </div>
        </Header>
        <div className="container">
          <Content>
            <div className="page-content">
              <div className="page-left-side">
                <FormCard>
                  <div>
                    <FormWithAccount />
                  </div>
                </FormCard>
              </div>
              <div className="info">
                <ProtectionPlanDetails />
              </div>
            </div>
          </Content>
        </div>
      </PageSelection>
    </Layout>
  )
}

export default ProtectionForm
